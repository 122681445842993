import React, { useState, useEffect } from "react";
import NavBar from "../../components/NavBar";
import NavButtons from "../../components/NavButtons";
import Modal from "../../components/Modal";
import { useHistory, useLocation, Redirect } from "react-router-dom";
import { Label, Input } from "@rebass/forms";
import { Box, Button } from "rebass";
import { postData, getData } from "../../services/cloud";
import "../../App.css";
import { isValidEmail } from "../../services/utils";
import { MAX_BOARDS } from "../../constants";
const { HomeButton, Spacer } = NavButtons;

const setMeetingValue = (setMeeting, newMeetingState) => {
  if (newMeetingState["ownerEmail"]) {
    newMeetingState["ownerEmail"] = newMeetingState["ownerEmail"]
      .trim()
      .toLowerCase();
  }

  setMeeting((prevState) => {
    const obj = {
      ...prevState,
      ...newMeetingState,
    };
    return obj;
  });
};

const hasValidBoardCount = (ownerEmail) => {
  return new Promise(async (resolve, reject) => {
    // Accesses all owners meetings via ownerEmail index
    if (ownerEmail === "codedplanet@gmail.com") {
      resolve({ boardCount: "valid" });
      return;
    }
    const response = await getData({ ownerEmail }, "meetingDetails");
    const { data = [] } = response;
    if (data.filter((c) => c.sk === "config").length < MAX_BOARDS) {
      resolve({ boardCount: "valid" });
    } else {
      reject({ boardCount: "not valid" });
    }
  });
};

const postMeetingDetails = async (meeting, setMeeting) => {
  return new Promise(async (resolve, reject) => {
    try {
      await hasValidBoardCount(meeting.ownerEmail);
      setMeetingValue(setMeeting, { posting: true });
      const pl = {
        meetingId: meeting.meetingId,
        meetingName: meeting.meetingName,
        ownerEmail: meeting.ownerEmail,
        participants: meeting.participants,
        sk: "config",
      };
      const res = await postData(pl, "meetings", {
        meetingId: meeting.meetingId,
      });
      setMeetingValue(setMeeting, { showLink: true, posting: false });
      resolve();
    } catch (error) {
      if (error.boardCount) {
        alert("Currently your board limit is one");
      }
      reject(error);
    }
  });
};

function ConfirmForm({ submit, meeting, setMeeting, loggedInEmail, setModal }) {
  return (
    <Box mx="auto" width={[400, 600]} as="form" onSubmit={submit} py={3}>
      <Box px={2} mt={4}>
        <Label mb={2} htmlFor="meeitng-name" sx={{ fontWeight: "bolder" }}>
          Meeting Name
        </Label>
        <Input
          id="meeting-name"
          tabIndex={1}
          name="meeting-name"
          placeholder="My meeting name"
          value={meeting.meetingName || ""}
          onChange={(e) => {
            const meetingName = e.target.value;
            setMeetingValue(setMeeting, { meetingName });
          }}
        />
      </Box>
      <Box px={2} mt={4} sx={{ textAlign: "left" }}>
        <div mb={2} style={{ display: "flex" }}>
          <div style={{ fontWeight: "bolder", paddingTop: 3 }}>
            Participants{" "}
          </div>
          <Box
            ml={3}
            onClick={() => {
              setModal({ open: true });
            }}
            variant="badge"
            sx={{ bg: "grey", p: 1, pr: 2, pl: 2, cursor: "pointer" }}
          >
            Add
          </Box>
        </div>

        <Box mt={3} variant="badge" mr={3}>
          {loggedInEmail}{" "}
        </Box>
        {meeting.participants.map((pEmail) => {
          return (
            <Box variant="badge" key={pEmail} mr={3}>
              {pEmail}
              <Box
                pl={2}
                onClick={() => {
                  const pVal = meeting.participants.filter((p) => p !== pEmail);
                  setMeetingValue(setMeeting, { participants: pVal });
                }}
                sx={{ display: "inline", cursor: "pointer" }}
              >
                x
              </Box>
            </Box>
          );
        })}
      </Box>
      <Box mt={5} px={2} ml="auto">
        <Button tabIndex={3} pl={35} pr={35}>
          Save Meeting
        </Button>
      </Box>
    </Box>
  );
}

function Confirm({ loggedInEmail }) {
  const history = useHistory();
  const location = useLocation();
  const [meeting, setMeeting] = useState({ showLink: false, participants: [] });
  const [modal, setModal] = useState({ open: false, current: "" });
  const meetingId = location.meetingId
    ? location.meetingId.replace(".jpg", "")
    : null;

  useEffect(() => {
    async function fetchData() {
      const response = await getData({ meetingId }, "meetingDetails");
      if (response && response.data && response.data.length === 1) {
        setMeeting((prevState) => ({ ...prevState, ...response.data[0] }));
      }
    }
    if (meetingId) {
      fetchData();
    }
  }, [meetingId]);

  if (!meetingId || !loggedInEmail) {
    return <Redirect to="/home" />;
  }

  const submit = async (e) => {
    e.preventDefault();
    if (!meeting.meetingName) {
      alert("Please add name");
    } else {
      const meetingObj = {
        meetingName: meeting.meetingName,
        ownerEmail: loggedInEmail,
        participants: meeting.participants,
        meetingId,
      };
      try {
        await postMeetingDetails(meetingObj, setMeeting);
        history.push(`/meeting/${meetingId}`);
      } catch (error) {
        console.log("err: ", error);
      }
    }
  };

  return (
    <div className="App" style={{ minHeight: "100vh", position: "relative" }}>
      {modal.open && (
        <Modal onClose={() => setModal(false)}>
          <div style={{ textAlign: "center" }}>
            <div style={{ height: 100, paddingTop: 20 }}>
              <h2>Add Email for a participant</h2>
              <Input
                id="participants"
                placeholder="Add email"
                tabIndex={2}
                name="participants"
                value={modal.current || ""}
                onChange={(e) => {
                  const meetingParticipant = e.target.value;
                  setModal((prevState) => ({
                    ...prevState,
                    current: meetingParticipant,
                  }));
                }}
              />
            </div>
            <Button
              mt={4}
              mb={4}
              variant="primary"
              onClick={() => {
                if (!isValidEmail(modal.current)) {
                  alert("Not valid email");
                  return;
                }
                const pVal = [
                  ...new Set([...meeting.participants, modal.current]),
                ];
                setMeetingValue(setMeeting, { participants: pVal });
                setModal({ open: false, current: "" });
              }}
            >
              Add
            </Button>
            <Button
              mt={4}
              mb={4}
              ml={3}
              variant="primary"
              onClick={() => {
                setModal({ open: false, current: "" });
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal>
      )}
      <NavBar
        title="Create Shared Meeting"
        leftOptions={[<HomeButton history={history} />]}
        rightOptions={[<Spacer />]}
      />
      <div className="App-headline headline-font">Confirm Details</div>
      <div>
        <ConfirmForm
          submit={submit}
          meeting={meeting}
          setMeeting={setMeeting}
          loggedInEmail={loggedInEmail}
          setModal={setModal}
        />
      </div>
      <footer className="Alt-app-footer">&copy; 2020 Stickies.zone</footer>
    </div>
  );
}

export default Confirm;
