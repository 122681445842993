import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Landing from "./containers/Landing";
import Home from "./containers/Home";
import Confirm from "./containers/Confirm";
import Meeting from "./containers/Meeting";
import ExistingMeetings from "./containers/ExistingMeetings";
import shortid from "shortid";
import { BLANK_USER } from "./constants";
import { Auth } from "aws-amplify";
import "./App.css";

function App() {
  const [state, setState] = useState({
    user: BLANK_USER,
    error: null,
    search: null,
  });

  useEffect(() => {
    async function checkUser() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setState((prevState) => ({
          ...prevState,
          user,
          error: null,
        }));
      } catch (error) {}
    }
    checkUser();
  }, [state.search, state.error]);

  const { user } = state;
  const loggedInEmail = user.attributes.email;
  if (!state.search && window.location.search) {
    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        search: `${window.location.search}`,
      }));
    }, 1000);
  }

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Landing setState={setState} user={state.user} />
        </Route>
        <Route exact path="/about">
          <div>about</div>
        </Route>
        <Route exact path="/home">
          <Home user={state.user} key={shortid.generate()} />
        </Route>
        <Route exact path="/confirm">
          <Confirm loggedInEmail={loggedInEmail} />
        </Route>
        <Route exact path="/meeting/:meetingId">
          <Meeting loggedInEmail={loggedInEmail} />
        </Route>
        <Route exact path="/meetings">
          <ExistingMeetings loggedInEmail={loggedInEmail} />
        </Route>
        <Route
          exact
          path="/authresponse"
          render={() => {
            return <Redirect to="/" />;
          }}
        />
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
