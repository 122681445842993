import React, { useState } from "react";
import NavBar from "../../components/NavBar";
import NavButtons from "../../components/NavButtons";
import { useHistory } from "react-router-dom";
import { Label, Input } from "@rebass/forms";
import { Box, Button } from "rebass";
import { isValidEmail } from "../../services/utils";
import "../../App.css";
const { HomeButton, Spacer } = NavButtons;

const setFormValue = (setForm, newFormState) => {
  if (newFormState["participantEmail"]) {
    newFormState["participantEmail"] = newFormState["participantEmail"]
      .trim()
      .toLowerCase();
  }

  setForm((prevState) => {
    return {
      ...prevState,
      ...newFormState,
    };
  });
};

function EntryForm({ submit, form, setForm }) {
  return (
    <Box mx="auto" width={[400, 600]} as="form" onSubmit={submit} py={3}>
      <Box px={2} mt={4}>
        <Label mb={2} htmlFor="meeitng-name" sx={{ fontWeight: "bolder" }}>
          Participant Email
        </Label>
        <Input
          id="participant-name"
          tabIndex={1}
          name="participant-name"
          placeholder="My participant email"
          value={form.participantEmail || ""}
          onChange={(e) => {
            const participantEmail = e.target.value;
            setFormValue(setForm, { participantEmail });
          }}
        />
      </Box>
      <Box mt={5} px={2} ml="auto">
        <Button tabIndex={3} pl={35} pr={35}>
          Enter
        </Button>
      </Box>
    </Box>
  );
}

function isOnParticpantList(participant, participants, loggedInEmail) {
  if (loggedInEmail === participant || participants.includes(participant)) {
    return true;
  }
  return false;
}

function Entry({ loggedInEmail, meetingId, serverConfig }) {
  const history = useHistory();
  const [form, setForm] = useState({});

  const submit = async (e) => {
    e.preventDefault();
    if (
      !isValidEmail(form.participantEmail) ||
      !isOnParticpantList(
        form.participantEmail,
        serverConfig.participants || [],
        serverConfig.ownerEmail
      )
    ) {
      alert(
        "Please enter a valid email. Email must also be on the participant list"
      );
    } else {
      history.push({
        pathname: `/meeting/${meetingId}`,
        author: form.participantEmail,
      });
    }
  };

  return (
    <div className="App" style={{ minHeight: "100vh", position: "relative" }}>
      <NavBar
        title="Enter Your Email"
        leftOptions={[<HomeButton history={history} />]}
        rightOptions={[<Spacer />]}
      />
      <div className="App-headline headline-font">Enter details</div>
      <div>
        <EntryForm submit={submit} form={form} setForm={setForm} />
      </div>
      <footer className="Alt-app-footer">&copy; 2020 Stickies.zone</footer>
    </div>
  );
}

export default Entry;
