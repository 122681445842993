import { useEffect, useRef } from "react";
import { syncMeeting } from "./serverSync";
import { startInterval } from "./utils";
import { SYNC_TIME_SECS } from "../constants";

function useServerSync(deps, state, setState, admin) {
  const [updateInterval, meetingId, author, isSyncing] = deps;
  /* eslint-disable */
  useEffect(() => {
    console.log("Sync to server runs if deps change");
    const meeting = {
      meetingId,
      annotations: state.annotations,
      author,
    };
    if (!state.movingObject && !isSyncing.current) {
      isSyncing.current = true;
      console.log("Sync to server called");
      syncMeeting(meeting, setState, state, admin, isSyncing);
    }
  }, [state.dirtyAnnotations, updateInterval, meetingId, author]);
  /* eslint-enable */
}
function useStartInterval(deps, setState) {
  const intervalId = useRef();
  const [validMeeting] = deps;
  useEffect(() => {
    console.log("Start interval runs if deps change");
    if (validMeeting) {
      intervalId.current = startInterval(setState, SYNC_TIME_SECS);
    }
    return () => clearInterval(intervalId.current);
  }, [validMeeting, setState]);
}

export { useServerSync, useStartInterval };
