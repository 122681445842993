import React from "react";
import { Flex, Text, Button } from "rebass";
import { FiLogOut } from "react-icons/fi";

const LandingNavBar = ({ onClick, email, logOut }) => {
  const name = email ? "Start" : "Login with Google";
  return (
    <Flex
      px={2}
      style={{ margin: "auto", paddingTop: 20 }}
      width="75%"
      alignItems="center"
    >
      <Text p={2} fontWeight="bold" fontSize={[3, 4]}>
        Stickies.zone
      </Text>
      <div style={{ flexGrow: 1 }}></div>
      <Button title={name} ml="auto" variant="primary" mr={2} onClick={onClick}>
        {name}
      </Button>
      {email && (
        <FiLogOut
          title="Log out"
          size={26}
          onClick={logOut}
          style={{ cursor: "pointer" }}
        />
      )}
    </Flex>
  );
};

export default LandingNavBar;
