/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:3fe91ba5-5166-4dd9-9e4c-dd79caef5048",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_9MoeB5k2y",
    "aws_user_pools_web_client_id": "37llule26e0pmohpf2u9adbpq4",
    "oauth": {
        "domain": "stickies8a2189c2-8a2189c2-prod.auth.eu-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.stickies.zone/",
        "redirectSignOut": "https://www.stickies.zone/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
