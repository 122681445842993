export default {
  breakpoints: ["40em", "52em", "64em"],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  colors: {
    blue: "#07c",
    lightgray: "#f6f6ff",
    primary: "tomato",
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fonts: {
    body: "system-ui, sans-serif",
    heading: "inherit",
    monospace: "Menlo, monospace",
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  shadows: {
    small: "0 0 4px rgba(0, 0, 0, .125)",
    large: "0 0 24px rgba(0, 0, 0, .125)",
  },
  variants: {
    card: {
      p: 2,
      bg: "background",
      boxShadow: "card",
      borderRadius: 2,
    },
    badge: {
      display: "inline-block",
      p: 1,
      color: "white",
      bg: "blue",
      borderRadius: 9999,
      pl: 3,
      pr: 3,
    },
  },
  text: {},
  buttons: {
    primary: {
      color: "white",
      bg: "primary",
      fontWeight: "bold",
    },
  },
};
