import React from "react";
import LandingNavBar from "../../components/LandingNavBar";
import { Auth } from "aws-amplify";
import Emoji from "../../components/Emoji";
import { BLANK_USER } from "../../constants";
import { useHistory } from "react-router-dom";
import svg from "../../img/video-call.svg";
import "../../App.css";

function Landing({ setState, user }) {
  const history = useHistory();
  const start = async () => {
    if (user.attributes.email) {
      setState((prevState) => ({
        ...prevState,
        user,
      }));
      history.push("/home");
      return;
    } else {
      Auth.federatedSignIn({ provider: "Google" });
    }
  };

  const signOut = async () => {
    await Auth.signOut();
    setState((prevState) => ({
      ...prevState,
      user: BLANK_USER,
    }));
  };

  return (
    <div className="App" style={{ minHeight: "100vh", position: "relative" }}>
      <LandingNavBar
        email={user.attributes.email}
        onClick={start}
        logOut={signOut}
      />
      <div className="App-header">
        Stickies
        <Emoji symbol="😍" label="love eyes" className="emoji-main" />
      </div>
      <div className="App-body">
        <div className="App-headline headline-font">
          Stickies.zone engaging collaboration online
        </div>
        <div className="App-illustration">
          <img className="App-svg" alt="stickies" src={svg} />
        </div>
        <div className="App-advert">
          <div>
            <Emoji symbol="👍" label="thumbs up" className="emoji" />
            Love stickies
          </div>
          <div>
            <Emoji symbol="👍" label="thumbs up" className="emoji" />
            Love design thinking
          </div>
          <div>
            <Emoji symbol="👍" label="thumbs up" className="emoji" />
            Love collaborating online
          </div>
          <div>
            <Emoji symbol="👍" label="thumbs up" className="emoji" />
            Love to vote on stickies
          </div>
        </div>
      </div>
      <footer className="App-footer">&copy; 2020 Stickies.zone</footer>
    </div>
  );
}

export default Landing;
