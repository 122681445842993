import React from "react";
import { FiHome, FiImage, FiArrowLeft } from "react-icons/fi";

const NavButtons = {};
const HomeButton = ({ history }) => (
  <FiHome
    onClick={() => history.push("/home")}
    title="Home"
    style={{
      color: "#FFF",
      cursor: "pointer",
      marginLeft: 10,
      width: 40,
      verticalAlign: "bottom",
      height: 26,
    }}
  />
);

const ExitButton = ({ onClick }) => (
  <FiArrowLeft
    onClick={onClick}
    title="exit"
    style={{
      color: "#FFF",
      cursor: "pointer",
      marginLeft: 10,
      width: 40,
      verticalAlign: "bottom",
      height: 26,
    }}
  />
);

const ImageButton = ({ onClick }) => (
  <FiImage
    title="Background"
    onClick={onClick}
    style={{
      color: "#FFF",
      cursor: "pointer",
      marginLeft: 10,
      width: 40,
      height: 26,
      marginBottom: -1,
      verticalAlign: "bottom",
    }}
  />
);

const Spacer = () => <div style={{ marginLeft: 10, width: 40 }}></div>;

NavButtons.HomeButton = HomeButton;
NavButtons.ImageButton = ImageButton;
NavButtons.ExitButton = ExitButton;
NavButtons.Spacer = Spacer;

export default NavButtons;
