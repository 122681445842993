const MAX_UPLOAD = 5;
const FILE_TYPE = "jpg";
const NAME = "guest";
const CANVAS_MARGIN = 200;
const CANVAS_MIN_WIDTH = 2400;
const CANVAS_MIN_HEIGHT = 1800;
const DEFAULT_BG_WIDTH = 2250;
const DEFAULT_BG_HEIGHT = 1558;
const SYNC_TIME_SECS = 15;
const CIRCLE_WIDTH = 30;
const VOTE_TYPE = "vote";
const TEXT_TYPE = "text";
const DEFAULT_CURSOR = "default";
const GRAB_CURSOR = "grabbing";
const VIEWER = "viewer";
const STICKY_TYPE = "sticky";
const STANDARD_PAD = 10;
const MAX_BOARDS = 1;
const BLANK_USER = { attributes: {} };

const BASE_URL = "https://fwbdvb3pld.execute-api.eu-west-2.amazonaws.com/dev";
const S3_URL = "https://cp-prod-assets.s3.eu-west-2.amazonaws.com";
//const BASE_URL = "http://localhost:8001/api/ddb";

export {
  MAX_UPLOAD,
  FILE_TYPE,
  NAME,
  CANVAS_MARGIN,
  CANVAS_MIN_WIDTH,
  CANVAS_MIN_HEIGHT,
  SYNC_TIME_SECS,
  CIRCLE_WIDTH,
  VOTE_TYPE,
  TEXT_TYPE,
  DEFAULT_CURSOR,
  GRAB_CURSOR,
  VIEWER,
  BASE_URL,
  S3_URL,
  STICKY_TYPE,
  STANDARD_PAD,
  DEFAULT_BG_HEIGHT,
  DEFAULT_BG_WIDTH,
  MAX_BOARDS,
  BLANK_USER,
};
