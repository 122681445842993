import React, { useEffect, useState } from "react";
import NavBar from "../../components/NavBar";
import NavButtons from "../../components/NavButtons";
import { useHistory } from "react-router-dom";
import { getData, deleteData } from "../../services/cloud";
import { Button } from "rebass";
import "../../App.css";
const { HomeButton, Spacer } = NavButtons;
const keyMap = { meetingId: "pk" };

async function deleteMeeting(meetingId, history) {
  await deleteData({ meetingId });
  history.go();
}

function ExistingMeetings({ loggedInEmail }) {
  const history = useHistory();
  const [existingMeetings, setExisitingMeetings] = useState([]);

  useEffect(() => {
    async function fetchData() {
      // Accesses all owners meetings via ownerEmail index
      const response = await getData(
        { ownerEmail: `${loggedInEmail}` },
        "meetingDetails"
      );
      if (response && response.data && response.data.length > 0) {
        setExisitingMeetings(response.data);
      }
    }
    if (loggedInEmail) {
      fetchData();
    }
  }, [loggedInEmail]);

  return (
    <div
      className="App"
      style={{
        position: "relative",
        minHeight: "100vh",
      }}
    >
      <NavBar
        title="Existing Meetings"
        leftOptions={[<HomeButton history={history} />]}
        rightOptions={[<Spacer />]}
      />
      <div className="App-headline headline-font">Select Option</div>
      <div style={{ paddingBottom: 200 }}>
        {existingMeetings.map((m) => {
          return (
            <div key={`ex-${m[keyMap.meetingId]}`}>
              <div
                className="grey-box"
                onClick={() =>
                  history.push({ pathname: `/meeting/${m[keyMap.meetingId]}` })
                }
              >
                <div className="inner-box">{m.meetingName}</div>
              </div>
              <Button
                onClick={() => deleteMeeting(m[keyMap.meetingId], history)}
              >
                Delete
              </Button>
            </div>
          );
        })}

        {existingMeetings.length === 0 && <div>No results</div>}
      </div>

      <footer className="Alt-app-footer">&copy; 2020 Stickies.zone</footer>
    </div>
  );
}

export default ExistingMeetings;
