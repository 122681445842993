import React from "react";
import leanCanvas from "../../img/bgs/lean-canvas.jpg";
import criticalQuadrant from "../../img/bgs/critical-quadrant.jpg";
import blankQuadrant from "../../img/bgs/blank-quadrant.jpg";

const BackgroundObject = ({ bgSize, canvas, bgName }) => {
  // TODO: Add options for non-blank
  const getTemplate = (bgName) => {
    let template = null;
    switch (bgName) {
      case "lean-canvas":
        template = leanCanvas;
        break;
      case "blank-quadrant":
        template = blankQuadrant;
        break;
      case "critical-quadrant":
        template = criticalQuadrant;
        break;
      default:
        break;
    }
    return template;
  };

  const bgStyle = {
    height: bgSize.h,
    width: bgSize.w,
    top: (canvas.height - bgSize.h) / 2,
    left: (canvas.width - bgSize.w) / 2,
    position: "absolute",
  };
  if (bgName) {
    bgStyle.backgroundImage = `url(${getTemplate(bgName)})`;
    bgStyle.backgroundRepeat = "no-repeat";
    bgStyle.backgroundPosition = "center";
  } else {
    bgStyle.backgroundColor = "#FFF";
  }
  return <div style={bgStyle}></div>;
};

export default BackgroundObject;
