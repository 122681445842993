import { postData, getData } from "./cloud";
import { VIEWER } from "../constants";

const serverResultsToArray = (serverResults) => {
  let results = [];
  if (serverResults.data && serverResults.data.length > 0) {
    serverResults.data.forEach((d) => {
      if (d.annotations) {
        results = results.concat(d.annotations);
      }
    });
  }
  return results;
};

const mergeAnnotations = (serverResults, prevState) => {
  const serverInvalid = !serverResults || serverResults.status !== 200;
  if (prevState.dirtyAnnotations.length > 0 || serverInvalid) {
    console.log("trusting local");
    // TODO: On some rare occurrence we need to merge
    // Anything new from server?
    // Anything deleted from server?
    // Anything updated from server?
    // If all 3 are no return our local state;
    return [...prevState.annotations];
  } else {
    // No changes locally trust the server results
    console.log("trusting server");
    return serverResultsToArray(serverResults);
  }
};

const getServerConfig = (configEntry, prevState) => {
  if (configEntry.length === 1) {
    // TODO: Adjust limits for voters
    const serverConfig = { ...configEntry[0] };
    delete serverConfig.pk;
    delete serverConfig.sk;
    return serverConfig;
  }
  return prevState.serverConfig;
};

const updateServer = (pl) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await postData(pl, "meetings", {
        meetingId: pl.meetingId,
        author: pl.author,
      });
      if (res.status === 200) {
        resolve(pl.author);
      } else {
        reject();
      }
    } catch (error) {
      console.log("err posting:", error);
      reject();
    }
  });
};

// If we are an admin we need to be able to update for any participant if the admin has been a change on their behalf
const syncMeeting = async (meeting, setState, state, admin, isSyncing) => {
  // 1. Upate server with own annotations (except if first load or viewer or nothing changed)
  const submittedAuthors = [];
  if (meeting.author !== VIEWER && state.dirtyAnnotations.length > 0) {
    const pl = {
      ...meeting,
      annotations: meeting.annotations.filter(
        (ma) => ma.author === meeting.author
      ),
    };
    const resAuthor = await updateServer(pl);
    if (resAuthor) {
      submittedAuthors.push(resAuthor);
    }
  } else {
    console.log("skip post to server due to no changes");
  }

  // Admin also POST for other authors
  if (admin) {
    for (const da of state.dirtyAnnotations) {
      const pl = {
        ...meeting,
        author: da,
        annotations: state.annotations.filter((a) => a.author === da),
      };
      const resAuth = await updateServer(pl);
      if (resAuth) {
        submittedAuthors.push(resAuth);
      }
    }
  }

  // 2. Get exiting meeting annotation data from server
  const serverResults = await getData(
    { meetingId: meeting.meetingId },
    "meetings"
  );

  // 3. Update local state with merged data from server
  setState((prevState) => {
    const { data = [] } = serverResults;
    const configEntry = data.filter((r) => r.sk === "config");
    if (!configEntry) {
      return prevState;
    }
    const serverConfig = getServerConfig(configEntry, prevState);
    const annotations = mergeAnnotations(serverResults, prevState);
    const obj = {
      ...prevState,
      annotations,
      serverConfig,
    };

    if (submittedAuthors.length > 0) {
      obj.dirtyAnnotations = prevState.dirtyAnnotations.filter(
        (da) => !submittedAuthors.includes(da)
      );
    }
    return obj;
  });

  isSyncing.current = false;
};

export { syncMeeting };
