import React from "react";
import MenuItem from "../MenuItem";
import { getVoteCount, showModal } from "../../services/utils";
import * as shortid from "shortid";
import { MAX_UPLOAD, VOTE_TYPE } from "../../constants";

const getItems = (items) => {
  return items.map((item) => ({
    ...item,
    onClick: () => {
      item.onClick();
    },
  }));
};

const ContextMenu = ({ pos, items }) => {
  return (
    <div
      id="contextMenu"
      style={{
        position: "absolute",
        border: "1px solid rgba(0,0,0,0.15)",
        borderRadius: "2px",
        boxShadow: "0 1px 1px 1px rgba(0,0,0,0.05)",
        padding: "10px 15px",
        background: "#f8f8f8",
        color: "#000",
        top: pos.y,
        left: pos.x,
        visibility: "visible",
        zIndex: 2147483646,
      }}
    >
      {getItems(items).map((item) => (
        <MenuItem item={item} key={item.label} icon={item.icon} />
      ))}
    </div>
  );
};

const getAnnotationItems = (setState, annotation) => {
  const mItem = (label, click, icon) => ({ label, onClick: click, icon });
  const deleteItem = mItem(
    "delete",
    () => {
      setState((prevState) => {
        return {
          ...prevState,
          annotations: prevState.annotations.filter(
            (a) => a.id !== annotation.id
          ),
          topRightMenu: null,
          dirtyAnnotations: [
            ...new Set(prevState.dirtyAnnotations.concat(annotation.author)),
          ],
        };
      });
    },
    "clear"
  );
  const cancelItem = mItem(
    "cancel",
    () => {
      setState((prevState) => ({
        ...prevState,
        topRightMenu: null,
      }));
    },
    "cancel"
  );
  return [cancelItem, deleteItem];
};

const getTopLevelItems = (setState, state, pos, author, hideVotes) => {
  const mItem = (label, click, icon) => ({ label, onClick: click, icon });
  const cancelItem = mItem(
    "cancel",
    () => {
      setState((prevState) => ({
        ...prevState,
        topRightMenu: null,
      }));
    },
    "cancel"
  );
  const addVoteItem = mItem(
    "add vote",
    () => {
      const totalAuthVotes = getVoteCount(state.annotations, VOTE_TYPE, author);
      if (totalAuthVotes === MAX_UPLOAD) {
        alert(`You have a maximum of ${MAX_UPLOAD} votes`);
        setState((prevState) => ({ ...prevState, topRightMenu: null }));
        return;
      }
      const newObj = {
        id: shortid.generate(),
        style: { top: pos.y, left: pos.x },
        type: VOTE_TYPE,
        author,
        createdDate: Date.now(),
        updatedDate: Date.now(),
      };
      setState((prevState) => ({
        ...prevState,
        annotations: prevState.annotations.concat(newObj),
        topRightMenu: null,
        dirtyAnnotations: [
          ...new Set(prevState.dirtyAnnotations.concat(author)),
        ],
      }));
    },
    "vote"
  );
  const addTextItem = mItem("add text", () => showModal(setState, pos), "text");
  const addStickyItem = mItem(
    "add sticky",
    () => showModal(setState, { ...pos, type: "sticky" }),
    "sticky"
  );

  if (hideVotes) {
    return [cancelItem, addTextItem];
  }
  return [cancelItem, addVoteItem, addTextItem, addStickyItem];
};

ContextMenu.getTopLevelItems = getTopLevelItems;
ContextMenu.getAnnotationItems = getAnnotationItems;

export default ContextMenu;
