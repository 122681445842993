import React from "react";
import { Box, Button } from "rebass";
import { postData } from "../../services/cloud";

const postMeetingDetails = async (serverConfig, meetingId, bgId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const pl = {
        meetingId: meetingId,
        meetingName: serverConfig.meetingName,
        ownerEmail: serverConfig.ownerEmail,
        participants: serverConfig.participants,
        bgName: bgId,
        sk: "config",
      };
      const res = await postData(pl, "meetings", {
        meetingId: meetingId,
      });
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

const setNewBG = async (
  setState,
  closeModal,
  serverConfig,
  meetingId,
  bgId
) => {
  await postMeetingDetails(serverConfig, meetingId, bgId);
  setState((prevState) => ({
    ...prevState,
    serverConfig: { ...serverConfig, bgName: bgId },
  }));
  closeModal();
};

const BGTag = (props) => {
  const { state, setState, closeModal, meetingId, bgName, bgId } = props;
  return (
    <Box
      tabIndex={3}
      mb={4}
      ml={2}
      variant="badge"
      onClick={() =>
        setNewBG(setState, closeModal, state.serverConfig, meetingId, bgId)
      }
    >
      {bgName}
    </Box>
  );
};

function ModalMeetingBGForm(props) {
  const { closeModal } = props;
  return (
    <div style={{ textAlign: "center" }}>
      <div style={{ marginBottom: 30, marginTop: 20 }}>
        Change your background to one of the options below.
        <div style={{ marginBottom: 10, marginTop: 40 }}>
          <BGTag {...props} bgName="Blank" bgId={null} />
          <BGTag {...props} bgName="Lean Canvas" bgId="lean-canvas" />
          <BGTag
            {...props}
            bgName="Critical Quadrant"
            bgId="critical-quadrant"
          />
          <BGTag {...props} bgName="Blank Quadrant" bgId="blank-quadrant" />
        </div>
      </div>
      <Button tabIndex={3} mb={4} ml={2} variant="primary" onClick={closeModal}>
        Close
      </Button>
    </div>
  );
}

export default ModalMeetingBGForm;
