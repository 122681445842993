import axios from "axios";
import { BASE_URL } from "../constants";

const getData = (query, collName) =>
  new Promise(async (resolve, reject) => {
    try {
      const resp = await axios({
        method: "POST",
        url: BASE_URL,
        data: {
          type: "find",
          collName: collName || "collabs",
          query: query || {},
        },
      });
      resolve(resp);
    } catch (e) {
      reject(e);
    }
  });

const deleteData = (query) =>
  new Promise(async (resolve, reject) => {
    try {
      const resp = await axios({
        method: "POST",
        url: BASE_URL,
        data: {
          type: "delete",
          collName: "meetings",
          query,
        },
      });
      resolve(resp);
    } catch (e) {
      reject(e);
    }
  });

const postData = (pl, collName, matcher) =>
  new Promise(async (resolve, reject) => {
    try {
      const resp = await axios({
        method: "POST",
        url: BASE_URL,
        data: transformPayload(pl, collName, matcher),
      });
      resolve(resp);
    } catch (e) {
      reject(e);
    }
  });

function transformPayload(payload, collName, matcher) {
  let transformedObject;
  const newPL = { ...payload };
  delete newPL["_id"];
  transformedObject = {
    type: "update",
    query: { matcher, setter: { $set: { ...newPL } } },
    collName: collName || "collabs",
    extraParams: { upsert: true },
  };
  return transformedObject;
}

export { getData, postData, deleteData };
