import React from "react";
import { Text } from "rebass";
import "./navbar.css";

const NavBar = ({ title, leftOptions, rightOptions }) => (
  <div className="nav-bar">
    {leftOptions &&
      leftOptions.map((lo, i) => {
        return <div key={`lo-${i}`}>{lo}</div>;
      })}
    <Text className="nav-title" fontWeight="bold" color="white" fontSize={[4]}>
      {title}
    </Text>
    {rightOptions &&
      rightOptions.map((ro, i) => {
        return <div key={`ro-${i}`}>{ro}</div>;
      })}
  </div>
);

export default NavBar;
