import React from "react";
import { Input } from "@rebass/forms";
import { Button } from "rebass";

function ModalMeetingForm({ state, setState, save, closeModal }) {
  return (
    <div style={{ textAlign: "center" }}>
      <div style={{ marginBottom: 30, marginTop: 20 }}>
        <Input
          id="modal-input"
          name="text-name"
          tabIndex={1}
          value={state.modalText || ""}
          placeholder="Enter text"
          onChange={(e) => {
            const modalText = e.target.value;
            setState((prevState) => ({
              ...prevState,
              modalText,
            }));
          }}
        />
      </div>
      <Button tabIndex={2} mb={4} mr={2} variant="primary" onClick={save}>
        Add
      </Button>
      <Button tabIndex={3} mb={4} ml={2} variant="primary" onClick={closeModal}>
        Close
      </Button>
    </div>
  );
}

export default ModalMeetingForm;
