import React from "react";
import PropTypes from "prop-types";
import "./modal.css";

const Modal = (props) => {
  document.onkeydown = (e) => {
    if (e.key === "Escape") {
      props.onClose(e);
    }
    // const focusable = document.querySelectorAll(
    //   'button:not(.modal-btn), [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    // );
    // focusable.forEach((el) => (el.tabIndex = -1));
  };

  return (
    <>
      <div className="Backdrop" style={{ zIndex: 2147483645 }}></div>
      <div
        className="Modal"
        style={{
          //   transform: props.show ? "translateY(0)" : "translateY(-100vh)",
          opacity: 1,
          zIndex: 2147483646,
        }}
        role="dialog"
        aria-labelledby={props.labelledby}
        aria-describedby={props.describedby}
      >
        <div
          className="modal-header"
          style={{ display: props.type !== "dialog" && "none" }}
        >
          <h2>{props.title}</h2>
        </div>
        <div className="modal-body">{props.children}</div>
      </div>
    </>
  );
};

Modal.propTypes = {
  /**
   * Set whether the modal is displayed
   */
  show: PropTypes.bool,
  labelledby: PropTypes.string,
  describedby: PropTypes.string,
  onClose: PropTypes.func,
};

Modal.defaultProps = {
  show: false,
  labelledby: "",
  onClose: null,
  describedby: "",
};

export default Modal;
