import React from "react";
import { GRAB_CURSOR } from "../../constants";

function changeCursor(setState, cursor) {
  setState((prevState) => ({ ...prevState, cursor }));
}

const moveFrame = (e, inputFrameRef, state) => {
  if (state.cursor === GRAB_CURSOR && state.mouseDown) {
    inputFrameRef.current.scrollTop =
      inputFrameRef.current.scrollTop - e.movementY;
    inputFrameRef.current.scrollLeft =
      inputFrameRef.current.scrollLeft - e.movementX;
  }
};

function ScrollingFrame({ children, forwardRef, cursor, setState, moveFrame }) {
  return (
    <div
      className="scroll-frame"
      ref={forwardRef}
      onMouseMove={(e) => moveFrame(e, forwardRef)}
      onMouseDown={(e) =>
        setState((prevState) => ({ ...prevState, mouseDown: true }))
      }
      onMouseUp={(e) =>
        setState((prevState) => ({ ...prevState, mouseDown: false }))
      }
      onMouseOut={(e) =>
        setState((prevState) => ({ ...prevState, mouseDown: false }))
      }
      onKeyDown={(e) => {
        if (e.keyCode === 32) {
          changeCursor(setState, "grabbing");
          e.preventDefault();
        }
      }}
      onKeyUp={(e) => {
        if (e.keyCode === 32) {
          changeCursor(setState, "default");
          e.preventDefault();
        }
      }}
      tabIndex="0"
      style={{
        cursor,
      }}
    >
      {children}
    </div>
  );
}

ScrollingFrame.moveFrame = moveFrame;

export default ScrollingFrame;
