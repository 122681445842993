import React from "react";
import NavBar from "../../components/NavBar";
import NavButtons from "../../components/NavButtons";
import { useHistory } from "react-router-dom";
import shortid from "shortid";
import "../../App.css";
const { ExitButton, Spacer } = NavButtons;

function Home({ loggedInEmail }) {
  let history = useHistory();

  const navToConfirm = () => {
    history.push({ pathname: "/confirm", meetingId: shortid.generate() });
  };

  return (
    <div className="App" style={{ minHeight: "100vh", position: "relative" }}>
      <NavBar
        title="Start"
        leftOptions={[<ExitButton onClick={() => history.push("/")} />]}
        rightOptions={[<Spacer />]}
      />
      <div className="App-headline headline-font">Select Option</div>
      <div className="grey-box" onClick={navToConfirm}>
        <div className="inner-box">
          Create
          <br />
          New
          <br />
          Meeting
        </div>
      </div>
      <div className="grey-box" onClick={() => history.push("/meetings")}>
        <div className="inner-box">
          View
          <br />
          Existing
        </div>
      </div>
      <footer className="Alt-app-footer">&copy; 2020 Stickies.zone</footer>
    </div>
  );
}

export default Home;
