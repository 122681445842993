import React from "react";
import * as shortid from "shortid";
import {
  DEFAULT_CURSOR,
  VIEWER,
  CIRCLE_WIDTH,
  VOTE_TYPE,
  TEXT_TYPE,
  STICKY_TYPE,
} from "../constants";
import ContextMenu from "../components/ContextMenu";

const styleCircle = {
  width: CIRCLE_WIDTH,
  height: CIRCLE_WIDTH,
};

const initialState = {
  movingObject: null,
  modalRef: null,
  modalText: "",
  annotations: [],
  selectedItemIndex: null,
  rightClick: null,
  topRightMenu: null,
  updateInterval: 0,
  mouseDown: false,
  loadedImage: null,
  warningMessage: null,
  ownerEmail: null,
  dirtyAnnotations: [],
};

function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return false;
}

function startInterval(setState, secs) {
  const id = setInterval(function () {
    setState((prevState) => ({
      ...prevState,
      updateInterval: prevState.updateInterval + secs,
    }));
  }, secs * 1000);
  return id;
}

function getVoteCount(annotations, type, author) {
  return annotations.filter(
    (a) => (!type || type === a.type) && (!author || author === a.author)
  ).length;
}

const adjustAnnotation = (config, pos) => {
  const { setState, annotation } = config;
  setState((prevState) => ({
    ...prevState,
    annotations: prevState.annotations.map((a) => {
      if (annotation.id !== a.id) {
        return a;
      }
      const newA = {
        ...a,
        style: { ...a.style, left: pos.x, top: pos.y },
        updatedDate: Date.now(),
      };
      return newA;
    }),
    movingObject: {
      ...prevState.movingObject,
      current: {
        ...prevState.movingObject.current,
        style: {
          ...prevState.movingObject.current.style,
          left: pos.x,
          top: pos.y,
        },
      },
    },
    modalRef: null,
    topRightMenu: null,
    modalText: "",
    dirtyAnnotations: [
      ...new Set(prevState.dirtyAnnotations.concat(annotation.author)),
    ],
  }));
};

const addAnnotation = (state, setState, author, pos) => {
  const newObj = {
    id: shortid.generate(),
    style: { top: pos.y, left: pos.x },
    text: state.modalText,
    type: pos.type || "text",
    author,
    createdDate: Date.now(),
    updatedDate: Date.now(),
  };

  setState((prevState) => ({
    ...prevState,
    annotations: prevState.annotations.concat(newObj),
    modalRef: null,
    topRightMenu: null,
    modalText: "",
    dirtyAnnotations: [...new Set(prevState.dirtyAnnotations.concat(author))],
  }));
};

const showModal = (setState, modalRef) => {
  setState((prevState) => ({ ...prevState, modalRef, topRightMenu: null }));
  setTimeout(() => {
    const inputModal = document.getElementById("modal-input");
    if (inputModal) {
      inputModal.focus();
    }
  }, 300);
};

const setMovingObejct = (setState, obj, admin) => {
  if (obj.author === obj.current.author || admin) {
    setState((prevState) => ({ ...prevState, movingObject: obj }));
  }
};

const resetMovingObejct = (setState) => {
  setState((prevState) => ({ ...prevState, movingObject: null }));
};

const moveAnnotation = (e, state, setState) => {
  if (state.movingObject && state.cursor === DEFAULT_CURSOR) {
    const pos = {
      x: state.movingObject.current.style.left + e.movementX,
      y: state.movingObject.current.style.top + e.movementY,
    };
    const config = {
      state,
      setState,
      author: state.movingObject.author,
      annotation: state.movingObject.current,
    };
    adjustAnnotation(config, pos);
  }
};

const rightClickAnnotation = (e, config, annotation) => {
  e.preventDefault();
  e.stopPropagation();
  const { setState, author, inputFrameRef, inputRef, admin } = config;
  if (author !== annotation.author && !admin) {
    return;
  }

  const scrollTop = inputFrameRef.current.scrollTop;
  const scrollLeft = inputFrameRef.current.scrollLeft;
  const x = e.pageX - inputRef.current.offsetLeft;
  const y = e.pageY - inputRef.current.offsetTop;
  const scrollAdjustedLeft = x + scrollLeft;
  const scrollAdjustedTop = y + scrollTop;

  setState((prevState) => ({
    ...prevState,
    topRightMenu: {
      pos: { x: scrollAdjustedLeft, y: scrollAdjustedTop },
      items: ContextMenu.getAnnotationItems(setState, annotation),
    },
  }));
};

const buildAnnotations = (config) => {
  const { state, setState, author, admin } = config;
  return state.annotations.map((a, i) => {
    // const zIndex = ZINDEX_START - i;
    const zIndexObj = a.updatedDate
      ? { zIndex: Math.round(a.updatedDate / 1000) }
      : {};
    if (a.type === VOTE_TYPE) {
      const deciderStyle = {};
      if (state.serverConfig && state.serverConfig.deciderName) {
        if (state.serverConfig.deciderName === a.author) {
          deciderStyle.backgroundColor = "darkblue";
        }
      }
      return (
        <div
          id={a.id}
          onContextMenu={(e) => rightClickAnnotation(e, config, a)}
          onMouseDown={(e) => {
            e.persist();
            if (e.button === 0) {
              setMovingObejct(setState, { current: { ...a }, author }, admin);
            }
          }}
          key={a.id}
          className="circle"
          style={{ ...styleCircle, ...a.style, ...zIndexObj, ...deciderStyle }}
        >
          &nbsp;
        </div>
      );
    } else if (a.type === TEXT_TYPE) {
      return (
        <div
          id={a.id}
          onContextMenu={(e) => rightClickAnnotation(e, config, a)}
          className="text-rect"
          key={a.id}
          onMouseDown={(e) => {
            if (e.button === 0) {
              setMovingObejct(setState, { current: { ...a }, author }, admin);
            }
          }}
          style={{ ...a.style, ...zIndexObj }}
        >
          {a.text}
        </div>
      );
    } else if (a.type === STICKY_TYPE) {
      return (
        <div
          id={a.id}
          onContextMenu={(e) => rightClickAnnotation(e, config, a)}
          className="text-sticky"
          key={a.id}
          onMouseDown={(e) => {
            if (e.button === 0) {
              setMovingObejct(setState, { current: { ...a }, author }, admin);
            }
          }}
          style={{ ...a.style, ...zIndexObj }}
        >
          <p className="sticky-child">{a.text}</p>
        </div>
      );
    } else {
      return null;
    }
  });
};

const rightClickFrame = (e, config) => {
  const {
    setState,
    state,
    inputFrameRef,
    inputRef,
    author,
    hideVotes,
  } = config;
  if (author === VIEWER) {
    return;
  }
  const scrollTop = inputFrameRef.current.scrollTop;
  const scrollLeft = inputFrameRef.current.scrollLeft;
  const x = e.pageX - inputRef.current.offsetLeft;
  const y = e.pageY - inputRef.current.offsetTop;
  const scrollAdjustedLeft = x + scrollLeft;
  const scrollAdjustedTop = y + scrollTop;

  setState((prevState) => ({
    ...prevState,
    topRightMenu: {
      pos: { x: scrollAdjustedLeft, y: scrollAdjustedTop },
      items: ContextMenu.getTopLevelItems(
        setState,
        state,
        {
          x: scrollAdjustedLeft - styleCircle.width / 2,
          y: scrollAdjustedTop - styleCircle.height / 2,
        },
        author,
        hideVotes
      ),
    },
  }));
  e.preventDefault();
};

const isValidEmail = (email) => {
  var re = /^$|(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export {
  getQueryVariable,
  startInterval,
  getVoteCount,
  adjustAnnotation,
  addAnnotation,
  showModal,
  isValidEmail,
  initialState,
  setMovingObejct,
  resetMovingObejct,
  rightClickAnnotation,
  moveAnnotation,
  buildAnnotations,
  rightClickFrame,
};
