import React from "react";
import PropTypes from "prop-types";
import {
  FaCog,
  FaBan,
  FaPoll,
  FaEdit,
  FaEraser,
  FaStickyNote,
} from "react-icons/fa";

const MenuItem = ({ item }) => {
  const { label, icon, onClick } = item;
  const isCancel = icon === "cancel";
  const isCog = icon === "cog";
  const isVote = icon === "vote";
  const isEdit = icon === "text";
  const isClear = icon === "clear";
  const isSticky = icon === "sticky";
  const marginRight = { marginRight: 10 };

  return (
    <span
      className="menuItem"
      onClick={onClick}
      key={label}
      style={{
        cursor: "pointer",
        fontSize: "14px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        marginBottom: "7px",
      }}
    >
      {isCog && <FaCog style={marginRight} />}
      {isCancel && <FaBan style={marginRight} />}
      {isVote && <FaPoll style={marginRight} />}
      {isEdit && <FaEdit style={marginRight} />}
      {isClear && <FaEraser style={marginRight} />}
      {isSticky && <FaStickyNote style={marginRight} />}
      {label}
    </span>
  );
};

MenuItem.propTypes = {
  item: PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    icon: PropTypes.string,
  }).isRequired,
};

export default MenuItem;
