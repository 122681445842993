import React, { useState, useRef } from "react";
import ScrollingFrame from "../../containers/ScrollingFrame";
import EntryForm from "../../containers/EntryForm";
import NavBar from "../../components/NavBar";
import ContextMenu from "../../components/ContextMenu";
import Modal from "../../components/Modal";
import NavButtons from "../../components/NavButtons";
import BackgroundObject from "../../components/BackgroundObject";
import ModalMeetingForm from "../ModalMeetingForm";
import ModalMeetingBGForm from "../ModalMeetingBGForm";
import { useParams, useLocation, Redirect, useHistory } from "react-router-dom";
import {
  CANVAS_MIN_WIDTH,
  CANVAS_MIN_HEIGHT,
  CANVAS_MARGIN,
  DEFAULT_CURSOR,
  DEFAULT_BG_WIDTH,
  DEFAULT_BG_HEIGHT,
} from "../../constants";
import "../../App.css";
import {
  buildAnnotations,
  initialState,
  moveAnnotation,
  resetMovingObejct,
  rightClickFrame,
  addAnnotation,
} from "../../services/utils";
import { useServerSync, useStartInterval } from "../../services/customHooks";
const { HomeButton, Spacer, ImageButton } = NavButtons;

function Meeting(props) {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const { hideVotes = false, admin = false } = props;
  const { author = props.loggedInEmail } = location;
  const inputRef = useRef();
  const inputFrameRef = useRef();
  const canvas = { width: CANVAS_MIN_WIDTH, height: CANVAS_MIN_HEIGHT };
  const { meetingId } = params;
  const bgSize = { h: DEFAULT_BG_HEIGHT, w: DEFAULT_BG_WIDTH };
  canvas.width = Math.max(bgSize.w + CANVAS_MARGIN, CANVAS_MIN_WIDTH);
  canvas.height = Math.max(bgSize.h + CANVAS_MARGIN, CANVAS_MIN_HEIGHT);

  const [state, setState] = useState({
    ...initialState,
    canvas,
    cursor: DEFAULT_CURSOR,
  });

  const { serverConfig = {} } = state;
  const validMeeting = !serverConfig.invalid && author;

  const isSyncing = useRef(false);

  // Custom hooks
  useServerSync(
    [state.updateInterval, meetingId, author, isSyncing],
    state,
    setState,
    admin
  );
  useStartInterval([validMeeting], setState);

  const showModal = state.modalRef !== null;
  const closeModal = () =>
    setState((prevState) => ({
      ...prevState,
      modalRef: null,
      showBGModal: null,
    }));

  const save = () => {
    //setState((prevState) => ({ ...prevState, modalText: "text" }));
    addAnnotation(state, setState, author, state.modalRef);
  };

  // Rendering
  if (!author) {
    return (
      <EntryForm meetingId={meetingId} serverConfig={state.serverConfig} />
    );
  }

  if (!state.serverConfig) {
    return <div className="App height-100">Loading...</div>;
  }

  if (state.serverConfig && state.serverConfig.invalid) {
    return <Redirect to="/home" />;
  }

  const meetingName =
    state.serverConfig && state.serverConfig.meetingName
      ? state.serverConfig.meetingName
      : "Digital Board";

  return (
    <div className="App height-100 unselectable">
      <div
        className="App-main"
        onMouseMove={(e) => moveAnnotation(e, state, setState)}
        onMouseUp={() => resetMovingObejct(setState)}
      >
        {showModal && (
          <Modal onClose={closeModal}>
            <ModalMeetingForm
              state={state}
              setState={setState}
              save={save}
              closeModal={closeModal}
            />
          </Modal>
        )}
        {state.showBGModal && (
          <Modal onClose={closeModal}>
            <ModalMeetingBGForm
              state={state}
              setState={setState}
              save={save}
              closeModal={closeModal}
              meetingId={meetingId}
            />
          </Modal>
        )}
        <NavBar
          title={meetingName}
          leftOptions={[
            <HomeButton history={history} />,
            <ImageButton
              onClick={() =>
                setState((prevState) => ({ ...prevState, showBGModal: true }))
              }
            />,
          ]}
          rightOptions={[<Spacer />, <Spacer />]}
        />
        <ScrollingFrame
          forwardRef={inputFrameRef}
          setState={setState}
          cursor={state.cursor}
          moveFrame={(e) => ScrollingFrame.moveFrame(e, inputFrameRef, state)}
        >
          <div
            className="App-frame"
            id="app-frame"
            onContextMenu={(e) =>
              rightClickFrame(e, {
                setState,
                state,
                inputFrameRef,
                inputRef,
                author,
                hideVotes,
              })
            }
            ref={inputRef}
            style={{
              // backgroundImage: `url(${state.bgImage})`,
              // backgroundColor: "white",
              width: state.canvas.width,
              height: state.canvas.height,
            }}
          >
            {state.topRightMenu && (
              <ContextMenu
                contextId="app-frame"
                items={state.topRightMenu.items}
                pos={state.topRightMenu.pos}
              />
            )}
            {state.rightClick && (
              <ContextMenu
                contextId={state.rightClick.id}
                items={state.rightClick.items}
                pos={state.rightClick.pos}
              />
            )}
            <BackgroundObject
              bgSize={bgSize}
              canvas={canvas}
              bgName={serverConfig.bgName}
            />
            {buildAnnotations({
              inputRef,
              inputFrameRef,
              state,
              setState,
              author,
              admin,
            })}
          </div>
        </ScrollingFrame>
      </div>
    </div>
  );
}

export default Meeting;
